import social_sensor from '../assets/images/webp/social-sensor.webp'
import social_tensor from '../assets/images/webp/social-tensor.webp'
import niche_branding from '../assets/images/png/niche-tensor-branding.png'
import niche_ui from '../assets/images/webp/niche-tensor-ui.webp'
import aperture_branding from '../assets/images/png/aperture-branding.png'
import aperture_ui from '../assets/images/webp/aperture-ui.webp'
import infini_branding from '../assets/images/png/infini-branding.png'
import tour_game_ui from '../assets/images/webp/tour-game-ui.webp'
import tour_branding from '../assets/images/png/tour-branding.png'
import cyber_branding from '../assets/images/png/cyber-connect-branding.png'
import cyber_ui from '../assets/images/webp/cyber-connect-ui.webp'
import link_branding from '../assets/images/png/link-branding.png'
import link_ui from '../assets/images/webp/link-ui.webp'
import tensor_market_brand from '../assets/images/png/tensor-market-ui.png'
import base_brand from '../assets/images/png/base-perp-brand.png'
import base_ui from '../assets/images/png/base-perp-ui.png'
import syntheia_ui from '../assets/images/png/syntheia-ui.png'
import syntheia_brand from '../assets/images/png/syntheia-brand.png'
import lcash_ui from '../assets/images/png/lcash-ui.png'
import lcash_brand from '../assets/images/png/lcash-brand.png'
import tabi_brand from '../assets/images/png/tabi-brand.png'
import tensure_land_brand from '../assets/images/png/tensure-land-brand.png'
import nft_brand from '../assets/images/png/nft-brand.png'
import nft_ui from '../assets/images/png/nft-ui.png'
import ontology_brand from '../assets/images/png/ontology-brand.png'
import ontology_ui from '../assets/images/png/ontology-ui.png'
import open_ocean_brand from '../assets/images/png/open-ocean-brand.png'
import open_ocean_ui from '../assets/images/png/open-ocean-ui.png'
import tao_brand from '../assets/images/png/tao-brand.jpg'
import tao_ui from '../assets/images/png/tao-ui.jpg'
import kuse from '../assets/images/webp/kuse.png'

export const NAV_ITEMS = [
  { name: 'BEDROCK LABS', href: '/' },
  { name: 'ABOUT', href: '/about' },
  {
    name: 'OUR STORY',
    href: 'https://inquisitive-wildflower-b20.notion.site/Bedrock-Labs-44aaf74111a1423e95fb7b790860b3df',
    target: '_blank',
  },
  { name: 'CASES', href: '/cases' },
  // { name: 'MEDIA', href: '/media' },
  { name: 'CONTACT', href: 'https://t.me/akamft', target: '_blank' },
]

export const SLIDER_LIST = [
  {
    text: 'Kuse',
    images: { cardOne: '', cardTwo: { image: kuse, title: 'Website Development' } },
  },
  {
    text: 'TourGames',
    images: { cardOne: tour_game_ui, cardTwo: tour_branding },
  },
  {
    text: 'TensorMarket',
    images: { cardOne: '', cardTwo: tensor_market_brand },
  },
  {
    text: 'NicheTensor',
    images: { cardOne: niche_ui, cardTwo: niche_branding },
  },
  { text: 'INFINI', images: { cardOne: '', cardTwo: infini_branding } },
  { text: 'Link3', images: { cardOne: link_ui, cardTwo: link_branding } },
  {
    text: 'CyberConnect',
    images: { cardOne: cyber_ui, cardTwo: cyber_branding },
  },
  {
    text: 'Aperture',
    images: { cardOne: aperture_ui, cardTwo: aperture_branding },
  },
  {
    text: 'SocialTensor',
    images: { cardOne: social_sensor, cardTwo: social_tensor },
  },
  {
    text: 'BasePerp',
    images: { cardOne: base_ui, cardTwo: base_brand },
  },
  {
    text: 'Syntheia',
    images: { cardOne: syntheia_ui, cardTwo: syntheia_brand },
  },
  {
    text: 'L.CASH',
    images: { cardOne: lcash_ui, cardTwo: lcash_brand },
  },
  {
    text: 'TABI',
    images: { cardOne: '', cardTwo: tabi_brand },
  },
  {
    text: 'TreasureLand',
    images: { cardOne: '', cardTwo: tensure_land_brand },
  },
  {
    text: 'NFTBlockchain',
    images: { cardOne: nft_ui, cardTwo: nft_brand },
  },
  {
    text: 'Ontology',
    images: { cardOne: ontology_ui, cardTwo: ontology_brand },
  },
  {
    text: 'OpenOcean',
    images: { cardOne: open_ocean_ui, cardTwo: open_ocean_brand },
  },
  {
    text: 'BingX',
    images: { cardOne: '', cardTwo: '' },
  },
  {
    text: 'Binance',
    images: { cardOne: '', cardTwo: '' },
  },
  {
    text: 'Huobi',
    images: { cardOne: '', cardTwo: '' },
  },
  {
    text: 'Microsoft',
    images: { cardOne: '', cardTwo: '' },
  },
  {
    text: 'GENERALTAOVENTURES',
    images: { cardOne: tao_ui, cardTwo: tao_brand },
  },
]
  .map((slide) => {
    return {
      text: slide.text.toUpperCase(),
      images: slide.images,
    }
  })
  .sort((a, b) => a.text.localeCompare(b.text))
